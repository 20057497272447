import { FooterPages } from './footer.enum';
import { Hero } from '../../components/Hero';
import {
    ClientTermsAndConditions,
    GeneralTermsAndConditions,
} from './PagesLinks/terms-and-conditions';

/**
 * General Information page
 * @returns {React.FC}
 */
const FooterLink: React.FC<{
    page: string;
}> = ({ page }) => {
    const description =
        'VOLKSWAGEN INSURANCE BROKERS, AGENTE DE SEGUROS Y DE FIANZAS, S.A. DE C.V.';
    const title = 'Términos y Condiciones';
    const component = () => {
        switch (page) {
            case FooterPages.CLIENT_TERMS:
                return ClientTermsAndConditions({ title });
            case FooterPages.GENERAL_TERMS:
                return GeneralTermsAndConditions({ title });
        }
    };

    return (
        <div className='o-page-wrap'>
            <div>
                <Hero title={title} description={description} />
            </div>
            {component()}
        </div>
    );
};

export default FooterLink;
