import { BronsonModal, BronsonSpinner } from '@dh/bronson-react';

import { insuranceLabels } from './labels/generic-message.labels';
import { Modal } from './interfaces/app.generic.types';

/**
 * Component Modal or Spinner, used to confirm deletion or edit .
 * @param terminationId termination identifier.
 */
export const modalComponent = (
    modalData: Modal,
    setModalData: (newModalData: Modal) => void,
) => {
    const { type, isHidden, isLoading, footerElements, footerCloseButton } =
        modalData;
    const modalInfo = insuranceLabels.modal[type];
    const content = isLoading ? (
        <span className='c-upload__loader'>
            <BronsonSpinner
                config={{
                    showSpinner: true,
                    spinnerWrapperClassModifier: 'c-spinner--center',
                }}
            />
        </span>
    ) : (
        <>
            {modalData.content || modalInfo.content || ''}
            <br />
        </>
    );

    if (!isHidden) {
        return (
            <div
                className='c-modal  c-modal--large'
                aria-hidden='false'
                id='demo-modal'
            >
                <BronsonModal
                    title={modalInfo.title}
                    isHidden={isHidden}
                    content={content}
                    onClose={() =>
                        setModalData({ ...modalData, isHidden: true })
                    }
                    footerCloseButton={footerCloseButton}
                    footerCloseButtonText={insuranceLabels.modal.cancel.button}
                    footerElements={footerElements}
                />
            </div>
        );
    }
};
