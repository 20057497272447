import { BronsonToastProps } from '@dh/bronson-react';

import { insuranceLabels } from '../labels/generic-message.labels';
import { ToastNotificationType } from '../enums';

/**
 * Toast data initialization
 */
export const toastInitError: BronsonToastProps = {
    isVisible: false,
    content: insuranceLabels.ToastNotifications.Service.Error,
    type: ToastNotificationType.Error,
    isClosable: true,
    autoClose: true,
};

/**
 * Toast data initialization
 */
export const toastInit: BronsonToastProps = {
    isVisible: false,
    content: '',
    type: 'default',
};
