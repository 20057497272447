/**
 * Generic message labels.
 */
export const insuranceLabels = {
    ToastNotifications: {
        Service: {
            Error: 'No es posible mostrar la información, intenta más tarde',
            Detail: 'No es posible mostrar la información -> [error]',
        },
    },
    modal: {
        processing: {
            title: 'Enviando respuesta',
        },
        cancel: {
            button: 'Cancelar',
        },
    },
};
