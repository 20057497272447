import { useKeycloak } from '@react-keycloak/web';

import { BronsonApp } from '../components/BronsonApp';
import ErrorPage from '../views/Error';
import FooterLink from '../views/Footer';
import { FooterPages } from '../views/Footer/footer.enum';
import { insuranceRoles } from '../config';
import Login from '../views/login';
import { PortalRoutes } from './routes.config';
import PrivacyPolicies from '../views/PrivacyPolicies';
import { PrivateRoute } from './utils';

import { BronsonSpinner, BronsonSpinnerProps } from '@dh/bronson-react';
import { forbiddenLabels, notFoundLabels } from '../views/Error/error.labels';
import { getUserRole, UserDataInterface } from '@dh/keycloak-auth';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const AppRouter = () => {
    const { keycloak, initialized } = useKeycloak();
    // keycloak token
    const userName = keycloak?.tokenParsed?.sub;
    const [loading, setLoading] = useState(true);

    const [userData, setUserData] = useState<UserDataInterface>();

    useEffect(() => {
        if (initialized) {
            const role = getUserRole(keycloak, insuranceRoles);
            if (userName && !keycloak?.authenticated) {
                setLoading(false);
                window.location.href = '#/login';
            } else if (userName && keycloak?.authenticated && !userData) {
                setUserData({ role, id: userName });
                setLoading(false);
            } else {
                setLoading(false);
            }
        }
    }, [initialized, userName, keycloak, loading, userData]);

    if (initialized && !loading) {
        return (
            <Router basename='/'>
                <BronsonApp userData={userData} loading={loading}>
                    <Switch>
                        {PortalRoutes.map((elem, index) => (
                            <PrivateRoute
                                key={index}
                                component={elem.component}
                                path={elem.path}
                                exact={elem.exact ? elem.exact : false}
                                allowedRoles={
                                    elem.allowedRoles
                                        ? elem.allowedRoles
                                        : undefined
                                }
                            ></PrivateRoute>
                        ))}
                        <Route path='/privacy-policy'>
                            <PrivacyPolicies />
                        </Route>
                        <Route path='/terms-and-conditions'>
                            <FooterLink page={FooterPages.GENERAL_TERMS} />
                        </Route>
                        <Route path='/client/terms-and-conditions'>
                            <FooterLink page={FooterPages.CLIENT_TERMS} />
                        </Route>
                        <Route path='/forbidden'>
                            <ErrorPage {...forbiddenLabels} />
                        </Route>
                        <Route path='/login' component={Login} />
                        <Route>
                            <ErrorPage {...notFoundLabels} />
                        </Route>
                    </Switch>
                </BronsonApp>
            </Router>
        );
    } else {
        const spinnerConfig: BronsonSpinnerProps = {
            showSpinner: true,
            spinnerWrapperClassModifier: 'c-spinner--text',
            showSpinnerText: true,
            spinnerText: 'Estamos configurando tu espacio de trabajo',
        };
        return <BronsonSpinner config={spinnerConfig}></BronsonSpinner>;
    }
};
