import { BronsonFormRadio } from '@dh/bronson-react';

import { DecisionForm } from './privacy-policies.type';
/**
 * Gets all content
 */
export const getContent = (data: DecisionForm, onChangeData) => (
    <div>
        {privacyPolicies()}
        <div className='o-inline-group u-mt u-mb-large'>
            <div className='o-inline-group__item'>
                <BronsonFormRadio
                    key={`${data.privacyPolicies.id}-true`}
                    elementId={`${data.privacyPolicies.id}-true`}
                    label='Sí'
                    defaultValue='true'
                    group={data.privacyPolicies.group}
                    onChange={(change) => {
                        onChangeData('privacyPolicies.value', change.value);
                    }}
                    isDisabled={data.privacyPolicies.isDisabled}
                    checked={data.privacyPolicies.value === 'true'}
                />
            </div>
            <div className='o-inline-group__item'>
                <BronsonFormRadio
                    key={`${data.privacyPolicies.id}-false`}
                    elementId={`${data.privacyPolicies.id}-false`}
                    label='No'
                    defaultValue='false'
                    group={data.privacyPolicies.group}
                    onChange={(change) => {
                        onChangeData('privacyPolicies.value', change.value);
                    }}
                    isDisabled={data.privacyPolicies.isDisabled}
                    checked={data.privacyPolicies.value === 'false'}
                />
            </div>
        </div>
        {personalDataProcessing()}
        <div className='o-inline-group u-mt u-mb-large'>
            <div className='o-inline-group__item'>
                <BronsonFormRadio
                    key={`${data.personalData.id}-true`}
                    elementId={`${data.personalData.id}-true`}
                    label='Acepto'
                    defaultValue='true'
                    group={data.personalData.group}
                    onChange={(change) => {
                        onChangeData('personalData.value', change.value);
                    }}
                    isDisabled={data.personalData.isDisabled}
                    checked={data.personalData.value === 'true'}
                />
            </div>
            <div className='o-inline-group__item'>
                <BronsonFormRadio
                    key={`${data.personalData.id}-false`}
                    elementId={`${data.personalData.id}-false`}
                    label='No acepto'
                    defaultValue='false'
                    group={data.personalData.group}
                    onChange={(change) => {
                        onChangeData('personalData.value', change.value);
                    }}
                    isDisabled={data.personalData.isDisabled}
                    checked={data.personalData.value === 'false'}
                />
            </div>
        </div>
    </div>
);

/**
 * Gets content personal data processing policy
 */
const privacyPolicies = () => (
    <>
        <p>
            En cumplimiento con lo dispuesto por la Ley Federal de Protección de
            Datos Personales en Posesión de los Particulares (en adelante, la
            Ley) y demás disposiciones aplicables, VOLKSWAGEN INSURANCE BROKERS,
            AGENTE DE SEGUROS Y DE FIANZAS, S.A. DE C.V (VWIB o el Responsable),
            con domicilio para oír y recibir notificaciones en Autopista México
            - Puebla, Kilómetro 116 más 900, San Lorenzo Almecatla,
            Cuautlancingo, Puebla, Código Postal 72700, informa al Solicitante /
            Cliente (titular de los datos), que tratará sus datos personales,
            incluyendo datos financieros y/o patrimoniales, para las siguientes
            finalidades primarias:
        </p>

        <p>
            <b>1.</b> Atender sus solicitudes de información acerca de nuestros
            productos y/o servicios, procesar solicitudes, negociar, colocar,
            intermediar y/o cotizar en el mercado asegurador y afianzador en
            México, intervenir en la contratación y/o renovación de seguros y
            fianzas, en sus ampliaciones o disminuciones de coberturas, así como
            en el pago de las primas correspondientes y otras
            contraprestaciones, asesoría y asistencia en casos de siniestros y
            reclamaciones ante las compañías aseguradoras o afianzadoras,
            atender consultas, aclaraciones y quejas relacionadas con la
            prestación del servicio de VWIB, entre otras finalidades señaladas
            en el Aviso de Privacidad Integral.
        </p>

        <p>
            VWIB también podrá tratar los datos personales de los titulares de
            los datos para las siguientes finalidades secundarias:
        </p>
        <p>
            <b>2.</b> Para contactarlo por diversos medios de comunicación para
            fines mercadotécnicos, publicitarios o de prospección comercial
            sobre productos, servicios y beneficios del Grupo VW, para fines
            estadísticos, contactarlo para conocer su nivel de satisfacción,
            realizar encuestas, evaluaciones y seguimiento de los servicios
            brindados, entre otras finalidades señaladas en el Aviso de
            Privacidad Integral. Favor de indicar en alguna de las casillas que
            aparecen más abajo si autoriza que VWIB realice el tratamiento de
            sus datos personales para las finalidades secundarias indicadas en
            este numeral.
        </p>
    </>
);

/**
 * Gets content personal data processing policy
 */
const personalDataProcessing = () => (
    <>
        <p>
            Los titulares de los datos podrán manifestar posteriormente su
            negativa para el tratamiento de sus datos personales para
            finalidades secundarias, limitar el uso o divulgación de sus datos,
            así como ejercer sus derechos de acceso, rectificación, cancelación
            y oposición (derechos ARCO) a través de los medios y procedimientos
            establecidos en el Aviso de Privacidad Integral que ha sido puesto a
            su disposición. Además, se le informa que éste puede ser consultado
            en la página de internet{' '}
            <a
                href='https://www.vwfs.mx/vwib/aviso-de-privacidad'
                target='_blank'
                className='u-text-brand'
                rel='noreferrer'
            >
                <strong>www.vwfs.mx/vwib/aviso-de-privacidad</strong>
            </a>
        </p>

        <p>
            VWIB podrá contactar al Solicitante / Cliente posterior al término
            de la intermediación de las pólizas de seguro o después de que VWIB
            haya recabado sus datos si es que no se intermedia una póliza de
            seguro, para brindarle información sobre productos, servicios y
            beneficios del Grupo VW una vez que finalice la vigencia de su
            póliza intermediada con VWIB y hasta por el plazo de dos años, lo
            que permitirá que Usted compare y elija la mejor opción en el
            mercado.
        </p>

        <p>
            En caso de que no desee ser contactado con posterioridad, Usted
            podrá ejercer en cualquier momento, su derecho de oposición a través
            del procedimiento señalado en el Aviso de Privacidad Integral de
            VWIB.
        </p>

        <p>
            Informados de todo lo anterior, expresamente el Solicitante /
            Cliente reconoce y acepta mediante el siguiente check box, que
            otorga su consentimiento expreso para que VWIB someta sus datos
            personales a tratamiento.
        </p>
    </>
);
