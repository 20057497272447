/**
 * Return terms component
 * @param param0 title
 * @returns Terms and conditions
 */
/* eslint-disable react/no-unescaped-entities */
export const ClientTermsAndConditions: React.FC<{
    title: string;
}> = ({ title }) => (
    <div style={{ textAlign: 'justify' }}>
        <h3>{title}</h3>
        <p className='u-pb-small'>
            Bienvenido a www.vfs.mx/vwib (este “Sitio” o “Sitio Web”). Por favor
            lea los siguientes términos y condiciones antes de usar esta página.
            Al entrar y utilizar este Sitio Web, Usted (el "Usuario") tendrá
            acceso a, y/o podrá utilizar diversos servicios financieros y
            contenidos (los “Servicios”) de Volkswagen Insurance Brokers Agente
            de Seguros y de Fianzas, SA de CV (“VWIB”), por lo que reconoce
            aceptar las condiciones y los términos aquí contenidos y declara
            expresamente la aceptación de lo siguiente:
        </p>
        <h4>Usos Permitidos</h4>
        <p className='u-pb-small'>
            El aprovechamiento de los Servicios es exclusiva responsabilidad del
            Usuario, quien en todo caso deberá servirse de ellos acorde a las
            funcionalidades permitidas en este Sitio y a los usos autorizados en
            los presentes Términos y Condiciones, por lo que el Usuario se
            obliga a utilizarlos de modo tal que no atenten contra las normas de
            uso y convivencia en Internet, las Leyes de los Estados Unidos
            Mexicanos y la legislación vigente en el país en que el Usuario se
            encuentre al usarlos. Este Sitio Web es para el uso personal del
            Usuario.
        </p>
        <p className='u-pb-small'>
            El Usuario conviene en no utilizar programas de cómputo,
            dispositivos o cualquier otro medio que interfiera o pueda
            interferir en las operaciones y/o actividades de este Sitio y/o de
            VWIB.
        </p>
        <h4>Derechos de Propiedad Industrial e Intelectual</h4>
        <p className='u-pb-small'>
            Los signos distintivos, el dominio y el contenido de este Sitio Web
            es propiedad de VWIB y/o de las empresas que conforman la
            Organización Volkswagen, o aparece con la autorización de los
            titulares de los derechos correspondientes, por tanto los derechos
            sobre dicho contenido se encuentran protegidos por las leyes
            mexicanas aplicables y por el derecho internacional.
        </p>
        <p className='u-pb-small'>
            Asimismo se encuentran protegidos los derechos sobre las palabras
            Volkswagen, y VWIB los logotipos que las identifican (no importando
            sus colores tipos de representaciones gráficas, forma, tamaños u
            otras características) y cualquier otro elemento que sirva para
            distinguir a los productos, siluetas, imágenes, servicios, marcas,
            lemas publicitarios, videos o cualesquiera otros elementos de las
            empresas de la Organización Volkswagen, que se encuentren contenidos
            actualmente o se inserten en el futuro. Ninguno de estos elementos
            puede ser utilizado sin el previo permiso por escrito de VWIB,
            quedando por tanto prohibida su reproducción, modificación,
            distribución, transmisión, re-publicación, exhibición o ejecución,
            así como cualquier otro uso del contenido de este sitio en alguna
            otra página de Internet o en alguna otra red informática, incluso en
            la publicidad y anuncios con relación a la conformación de este
            sitio.
        </p>
        <p className='u-pb-small'>
            Queda prohibido al Usuario el intentar obtener, u obtener
            información, mensajes, archivos de sonido y/o de imagen (incluyendo
            fotografías, grabaciones, videos, dibujos, etc.) o cualquier otro
            elemento de este sitio empleando cualquier medio distinto a los
            puestos expresamente a disposición del usuario para tal fin. Las
            descargas (downloads) para uso estrictamente personal están
            permitidas, más no así su reproducción para cualquier otro
            propósito. La descarga de cualquier elemento contenido en este sitio
            (incluyendo cualquier programa conectado por hipertexto o ligas
            (links) sólo se autoriza para efectos de su aplicación en la visita
            de nuestro sitio, por lo que para cualquier uso distinto el Usuario
            quedará sujeto a las políticas, condiciones y obligaciones
            establecidos por los titulares de los derechos sobre el elemento
            objeto de descarga.
        </p>
        <p className='u-pb-small'>
            De igual forma el Usuario deberá abstenerse de intentar realizar, o
            realizar cualquier tipo de adición, supresión, transformación o
            cualquier otra forma de alteración al contenido de este Sitio y,
            asimismo, el Usuario no deberá suprimir o alterar en forma alguna,
            cualquier dato que identifique la titularidad de derechos sobre
            cualquier contenido de este sitio.
        </p>
        <p className='u-pb-small'>
            El Usuario no podrá vincular este Sitio Web con otro sitio de
            Internet, ya sea mediante ligas, marcos o de cualquier otra forma,
            sin la autorización previa y por escrito de de VWIB, por lo que VWIB
            se reserva el derecho de desactivar del Sitio cualquier liga o marco
            no autorizado.
        </p>
        <p className='u-pb-small'>
            Cualquier uso no autorizado del contenido de este Sitio Web será
            violatorio de la Ley de la Propiedad Industrial, Leyes Civiles,
            Mercantiles, Penales y demás Leyes Nacionales o Internacionales, así
            como Tratados, aplicables.
        </p>
        <h4>Restricciones de Acceso</h4>
        <p className='u-pb-small'>
            VWIB tendrá el derecho a negar, limitar condicionar o prohibir a los
            Usuarios el acceso a este Sitio Web, ya sea de manera total o
            parcial, a su entera discreción. VWIB se reserva el derecho de
            modificar los Servicios de este Sitio Web en cualquier momento, sin
            necesidad de previo aviso.
        </p>
        <p className='u-pb-small'>
            Los Usuarios reconocen y aceptan que algunos de los Servicios
            únicamente pueden ser utilizados en ciertas áreas geográficas y con
            posterioridad al registro previo por parte de los Usuarios y/o pago
            de una comisión, según se indique en las condiciones particulares
            del servicio de que se trate.
        </p>
        <p className='u-pb-small'>
            A fin de garantizar la seguridad y confidencialidad de operaciones
            realizadas a través de los Servicios, es necesaria la previa
            identificación y autenticación del Usuario en el sistema, a través
            de la solicitud de clave de acceso y contraseña. Cualquiera de
            dichas operaciones estará sujeta a los términos y condiciones
            establecidos en el contrato respectivo entre VWIB y el Usuario, y
            sujeto a los Términos y Condiciones aquí establecidos. Todos los
            Servicios ofrecidos por VWIB son de acuerdo a la legislación
            mexicana aplicable y vigente y han sido autorizados por las
            autoridades competentes.
        </p>
        <h4>Restricciones para Usuarios menores de edad y/o incapaces</h4>
        <p className='u-pb-small'>
            Para los Usuarios menores de edad y/o incapaces es obligatorio
            contar con permiso y vigilancia de su padre, curador o tutor antes
            de:
        </p>
        <ul
            className='c-ul u-ph-small'
            style={{ listStyleType: 'lower-alpha' }}
        >
            <li className='u-mv-xsmall'>
                Escribir al Sitio, acceder a este o pedir que se envíe cualquier
                tipo de información.
            </li>
            <li className='u-mv-xsmall'>
                Mandar cualquier tipo de información al Sitio.
            </li>
            <li className='u-mv-xsmall'>
                Participar en cualquier tipo de juego, concurso, rifa o sorteo
                que requiera información personal u ofrezca un premio y/o que
                requiera información.
            </li>
            <li className='u-mv-xsmall'>
                Inscribirse a un grupo o club del Sitio.
            </li>
        </ul>
        <p className='u-pb-small'>
            Para cualquier responsabilidad derivada del uso o interacción con
            este sitio por personas que carezcan de capacidad de ejercicio, será
            tratada conforme a los supuestos de Ley aplicables a quienes ejercen
            la patria potestad, tutela o curatela
        </p>
        <h4>Transmisión de Información</h4>
        <p className='u-pb-small'>
            Se prohíbe la publicación y envío a este o desde este Sitio Web, de
            manera enunciativa mas no limitativa cualquier material que sea
            difamatorio, obsceno, pornográfico, ofensivo, profano, amenazante
            y/o violatorio de ley, así como cualquier material que constituya o
            aliente cualquier conducta que pudiera considerarse como actividad
            criminal y/o provocar actos criminales o contrarios a ley y/o
            inducir a error o confusión a otros usuarios y/o cualquier tipo de
            propaganda o avisos comerciales, políticos, religiosos o de
            cualquier otra naturaleza.
        </p>
        <p className='u-pb-small'>
            Cuando el Usuario transmita información a este Sitio, acepta
            expresamente ceder a VWIB la titularidad sobre dicha información y
            la faculta para usarla a su discreción, salvo por las excepciones
            previstas en nuestro Aviso de Privacidad para el manejo de la
            información personal del Usuario.
        </p>
        <h4>Política de Administración de Información</h4>
        <p className='u-pb-small'>
            Es nuestro deseo que el Usuario tenga una visita agradable a este
            sitio. También es la política de VWIB el respetar y proteger la
            privacidad de todos nuestros visitantes. Por ello, tenemos que
            establecer ciertas reglas básicas para hacer que todos se sientan
            confortables y seguros: Cuando el Usuario accede a nuestro sitio,
            puede hacerlo en forma totalmente anónima, no obstante nos
            reservamos el derecho de reunir información con relación a la
            duración de su visita, las páginas que el Usuario visualiza y las
            rutas virtuales que el mismo tome.
        </p>
        <p className='u-pb-small'>
            Esta información por si misma no identifica a un Usuario en
            específico. La información que recolectamos cuando se visita nuestro
            Sitio Web puede incluir el URL (Uniform Resource Locator) del Sitio
            Web de donde el usuario estuvo antes de accesar nuestro Sitio Web,
            que paginas visitó en nuestro Sitio, a que URL va después, que
            Browser utilizó para accesar nuestro Sitio, el protocolo de
            dirección de internet ("IP") y cualquier termino de búsqueda
            introducido a nuestro Sitio.
        </p>
        <p className='u-pb-small'>
            VWIB utiliza "cookies" y conexiones de hipertexto o ligas (links) a
            través del sitio para mejorar y personalizar la visita del Usuario.
            El Usuario puede optar por desactivar la funcionalidad de las
            "cookies" dentro de su buscador (browser), pero al hacerlo el
            Usuario probablemente no podrá gozar de aspectos específicos de
            alguna parte de este sitio. Las "cookies" son básicamente "asientos
            de memoria" que pueden quedar almacenadas dentro de la computadora
            del Usuario. Estas "cookies" no nos proporcionan información
            personal de Usted. Por otra parte, usamos las ligas (links) de
            hypertexto para conectarle a Usted con otros sitios relevantes. Sin
            embargo, no somos responsables por el contenido y experiencias fuera
            de nuestras páginas, pues el establecimiento de dichos links en
            nuestro sitio no implica la aceptación, aprobación o recomendación
            de VWIB respecto a la persona física o moral que sea titular de
            dichas páginas o respecto de los contenidos o servicios allí
            ofrecidos al público.
        </p>
        <p className='u-pb-small'>
            En algunas ocasiones nosotros requerimos en forma expresa
            información personal. El proporcionar esta información queda sujeto
            a la voluntad del Usuario y a los términos y condiciones
            establecidos en nuestro Aviso de Privacidad.
        </p>
        <p className='u-pb-small'>
            VWIB se reserva el derecho de cambiar su política de administración
            de información cuando el negocio así lo requiera. Todo cambio o
            adición a los términos de la Política de administración de
            información serán publicados en este Sitio, para que el Usuario
            siempre tenga el conocimiento de los términos y condiciones que
            rigen este Sitio, los cuales deberán ser revisados antes de proceder
            a su visita.
        </p>
        <h4>Responsabilidades</h4>
        <p className='u-pb-small'>
            VWIB, preocupado por ofrecer un Sitio confiable y de calidad a todos
            sus Usuarios, procura revisar y actualizar de tiempo en tiempo los
            elementos que conforman el contenido de este Sitio, siendo sin
            embargo pertinente informar al Usuario que además de las exclusiones
            de responsabilidad que se puedan mencionar específicamente en este
            Sitio, en forma enunciativa mas no limitativa, se encuentran las que
            se indican a continuación:
        </p>
        <ul
            className='c-ul u-ph-small'
            style={{ listStyleType: 'lower-alpha' }}
        >
            <li className='u-mv-xsmall'>
                VWIB, no asume ninguna responsabilidad por el funcionamiento o
                cualesquiera daños y/o perjuicios que el Usuario estime puedan
                derivarse de la interacción de nuestro sitio con su equipo de
                cómputo y/o con los accesorios periféricos o paralelos de dicho
                equipo y/o con las líneas de enlace entre su equipo y nuestro
                Sitio, ni acepta ninguna responsabilidad por cualquier daño o
                perjuicio derivado de presencia de virus, de elementos que
                puedan ofender o considerarse lesivos por el Usuario, ni por
                cualquier otro elemento que pudiera ser introducido por
                cualquier tercero violando los controles que tenemos en nuestro
                Sitio.
            </li>
            <li className='u-mv-xsmall'>
                No se garantiza la disponibilidad y continuidad total o parcial
                de este Sitio o las secciones que lo integran. VWIB no será
                responsable por cualquier daño o perjuicio derivado de la falta
                de continuidad o disponibilidad de conexión a este Sitio.
            </li>
            <li className='u-mv-xsmall'>
                VWIB no asume ninguna responsabilidad por cualquier daño o
                perjuicio derivado de
                <ul
                    className='c-ul u-ph-small'
                    style={{ listStyleType: 'disc' }}
                >
                    <li className='u-mv-xsmall'>
                        El uso inadecuado por parte de los Usuarios y visitantes
                        de este sitio respecto de los elementos que conforman su
                        contenido.
                    </li>
                    <li className='u-mv-xsmall'>
                        La violación o afectación de cualquier derecho de
                        propiedad industrial o intelectual, secreto industrial,
                        derechos de la personalidad, o cualquier otro derecho de
                        terceros, derivado de la difusión, explotación o
                        almacenamiento de cualquier elemento de nuestro Sitio
                        que se realice por los visitantes del mismo.
                    </li>
                    <li className='u-mv-xsmall'>
                        La comisión de actos de competencia o publicidad desleal
                        por parte de cualquier tercero que hubiera obtenido
                        elementos de este Sitio.
                    </li>
                    <li className='u-mv-xsmall'>
                        Que este Sitio no satisfaga las expectativas subjetivas
                        del Usuario o la información o los Servicios no le sean
                        útiles para cualquier fin específico deseado por dicho
                        Usuario.
                    </li>
                    <li className='u-mv-xsmall'>
                        El funcionamiento de cualquiera de los hipertextos o
                        ligas (links) contenidos en este Sitio y/o la calidad,
                        licitud, fiabilidad y utilidad de los productos,
                        servicios, datos o cualesquiera elementos de los
                        contenidos en las páginas enlazadas, por lo que los
                        titulares de cada una de dichas páginas serán
                        responsables por los servicios, productos, información y
                        demás condiciones y/o obligaciones que por si mismos
                        ofrezcan a través de sus páginas u otros medios
                        publicitarios.
                    </li>
                    <li className='u-mv-xsmall'>
                        Cualquier acceso a hipertextos o ligas desde este Sitio
                        será responsabilidad exclusiva del Usuario, por lo que
                        libera a VWIB de cualquier responsabilidad que se le
                        pretendiera imputar por dicho hecho.
                    </li>
                </ul>
            </li>
            <li className='u-mv-xsmall'>
                Por el acceso y uso de nuestro Sitio y de los Servicios, en
                ningún momento ni bajo ningún concepto el Usuario podrá
                considerar que goza de cualquier derecho, autorización o
                licencia para el uso y/o explotación de cualesquiera de los
                elementos que conforman el contenido de este Sitio con fines
                distintos a los que se establecen en estos Términos y
                Condiciones.
            </li>
            <li className='u-mv-xsmall'>
                Es posible que La información contenida en este Sitio no tenga
                la precisión, el detalle o la exactitud requerida por el
                Usuario, por lo que sí se desea obtener mayor información puede
                comunicarse al Centro de Atención Telefónica.
            </li>
            <li className='u-mv-xsmall'>
                La información de este Sitio está enfocada a Servicios ofrecidos
                y prestados el mercado de los Estados Unidos Mexicanos.
            </li>
            <li className='u-mv-xsmall'>
                La información contenida en este Sitio, salvo mención expresa en
                otro sentido, no deberá considerarse en ninguna forma como la
                realización de una oferta de contratar hecha al Usuario.
            </li>
            <li className='u-mv-xsmall'>
                Cualquier comentario, sugerencia, propuesta, estudio, oferta o
                en general cualquier tipo de información que el Usuario envíe
                por medio de los correos electrónicos y/o teléfonos y/o faxes
                y/o cualquier otro medio de comunicación que se refiera en este
                sitio, no implicará la aceptación de VWIB o cualquier empresa de
                la Organización Volkswagen de ningún tipo de compromiso u
                obligación hacia el Usuario remitente.
            </li>
            <li className='u-mv-xsmall'>
                De igual forma, los correos electrónicos y/o teléfonos y/o faxes
                y/o cualquier otro medio de comunicación que se refiera en este
                Sitio, no serán medios aceptados por VWIB o cualquier empresa de
                la Organización Volkswagen para la recepción por éstas de
                cualquier tipo de notificación judicial o extrajudicial,
                demandas, emplazamientos, requerimientos de autoridad o
                cualquier otro tipo de información distinta a la remitida para
                fines de consulta o realización de comentarios.
            </li>
        </ul>
        <br />
        <h4>Responsabilidad a cargo del Usuario</h4>
        <ul
            className='c-ul u-ph-small'
            style={{ listStyleType: 'lower-alpha' }}
        >
            <li className='u-mv-xsmall'>
                Tanto el acceso a este Sitio como el uso que pueda hacerse de la
                información contenida en el mismo, incluyendo recomendaciones y
                sugerencias, son de la exclusiva responsabilidad de quien los
                realiza.
            </li>
            <li className='u-mv-xsmall'>
                El Usuario será el responsable único y final del uso de los
                números de sus cuentas, contraseñas personales, claves de acceso
                y números confidenciales con los cuales tenga acceso a este
                Sitio y a los Servicios. El Usuario notificará a VWIB inmediata
                y fehacientemente respecto de cualquier uso no autorizado de sus
                números y claves, así como el ingreso a este Sitio por terceros
                no autorizados.
            </li>
            <li className='u-mv-xsmall'>
                El Usuario se obliga a sacar en paz y a tener a salvo a VWIB
                respecto de cualquier gasto, daño y en general de cualquier
                pérdida que llegue a sufrir, en virtud de cualquier violación
                que se haya cometido con respecto de los Términos y Condiciones
                aquí contenidos o de cualquier Ley aplicable, que se haya
                realizado o verificado por medio del acceso del Usuario o el uso
                de la cuenta de acceso de éste.
            </li>
        </ul>
        <h4>Sanciones</h4>
        <p className='u-pb-small'>
            VWIB se reserva el derecho de condicionar, suspender o cancelar,
            temporal o definitivamente el acceso del Usuario a este Sitio, en
            cualquier momento, e iniciar las acciones legales que correspondan,
            si el Usuario quebranta cualquiera de las disposiciones de los
            Términos y Condiciones aquí establecidos, o comete cualquier otro
            acto contrario a las Leyes aplicables vigentes.
        </p>
        <h4>Competencia, jurisdicción y derecho aplicable</h4>
        <p className='u-pb-small'>
            El Usuario al hacer uso de este sitio, reconoce que las operaciones
            comerciales que se dan entre VWIB y sus clientes se encuentran
            regidas por las leyes aplicables de los Estados Unidos Mexicanos y
            cualquier controversia que se derive de la aplicación de las mismas,
            se ventilará ante las autoridades y los tribunales de la ciudad de
            Puebla, Puebla., renunciando expresamente a cualquier otra
            jurisdicción que pudiera corresponderles por razón de su domicilio
            presente o futuro.
        </p>
        <p className='u-pb-small'>
            VWIB se reserva expresamente el derecho de actualizar en cualquier
            momento los presentes Términos y Condiciones. Estos serán los
            manifestados al momento de su uso real. El Usuario deberá visitar y
            revisar los ASPECTOS LEGALES con anterioridad cada vez que tenga
            acceso a este sitio para conocer y aceptar los Términos y
            Condiciones vigentes al momento de su visita. Si los cambios que
            realice VWIB son importantes, se harán del conocimiento del Usuario
            con oportunidad y mediante avisos destacados al respecto.
        </p>
        <p className='u-pb-small'>
            NOTA: El Usuario podrá haber llegado a este sitio a través del
            dominio de sitio de alguna de las empresas de la Organización
            Volkswagen, por lo que los presentes términos y condiciones podrán
            resultar aplicables a dichos sitios, salvo disposición en contrario
            y en cuyo caso se considerarán las siguientes salvedades:
            <ul
                className='c-ul u-ph-small'
                style={{ listStyleType: 'lower-alpha' }}
            >
                <li className='u-mv-xsmall'>
                    La información proporcionada por el Usuario podrá quedar
                    registrada en este Sitio o en el sitio que haya accedido con
                    anterioridad.
                </li>
                <li className='u-mv-xsmall'>
                    Los productos o servicios ofertados por cualquiera de las
                    empresas de la Organización Volkswagen, son responsabilidad
                    de la empresa que las oferta y no de VWIB.
                </li>
            </ul>
        </p>
    </div>
);

export const GeneralTermsAndConditions: React.FC<{
    title: string;
}> = ({ title }) => (
    <div style={{ textAlign: 'justify' }}>
        <h3>{title}</h3>
        <p className='u-pb-small'>
            Los presentes Términos y Condiciones de Uso (en adelante denominados
            como los “Términos y Condiciones”) regulan el acceso y uso del
            contenido por Volkswagen Financial Services. Al entrar, navegar y/o
            utilizar el Sitio, el "Usuario" reconoce y acepta y se obliga a
            todos y cada uno de los Términos y las Condiciones siguientes:
        </p>
        <h4>Objeto</h4>
        <p className='u-pb-small'>
            Los presentes Términos y Condiciones regulan el uso y navegación a
            través de la Plataforma, así como los Servicios ofrecidos y el
            Contenido confidencial a través del mismo.
        </p>
        <p className='u-pb-small'>
            Para efectos de estos Términos y Condiciones se entenderá por
            “Usuario” a cualquier persona física mayor de edad o persona moral
            mexicana o cuyo domicilio o establecimiento se encuentren ubicados
            en México, que acceda a la Plataforma y/o a cualquiera de sus
            páginas derivadas para conocer su contenido, solicitar, cargar,
            modificar, extraer, enviar o descargar información.
        </p>
        <p className='u-pb-small'>
            Esta Aplicación y algunos sitios desde la cual se puede
            redireccionar al Usuario, contiene o puede llegar a contener
            información relativa a las empresas del Grupo Volkswagen o así como
            sus productos, servicios y programas, incluyendo publicidad,
            promociones, otra información y otro contenido, que puede
            presentarse en forma de texto, datos, música, sonidos, gráficos,
            imágenes, fotografías, videos, software o de otra manera.
        </p>
        <h4>Navegación</h4>
        <p className='u-pb-small'>
            Para poder acceder a esta Plataforma, el Usuario debe estar
            autorizado por Volkswagen Financial Services previamente, en
            términos de sus respectivos Avisos de Privacidad y de acuerdo a las
            políticas que para tal efecto le dé a conocer y previamente el
            Usuario cumpla con ellas.
        </p>
        <h4>Prohibiciones</h4>
        <p className='u-pb-small'>
            El Usuario tiene prohibido suplantar la identidad, es decir, accesar
            con cuentas falsas o en nombre de otro Usuario, así como suprimir y
            modificar la información personal de los clientes. Si Volkswagen
            Financial Services tiene motivos razonables para sospechar que la
            información proporcionada por el Usuario antes del uso o durante el
            uso de la Plataforma es falsa, imprecisa o incompleta, Volkswagen
            Financial Services se reserva el derecho a denegar, con o sin previo
            aviso, el uso de la Plataforma.
        </p>
        <p className='u-pb-small'>
            Al acceder a y utilizar la Plataforma Digital, el Usuario se
            compromete a NO:
        </p>
        <ul
            className='c-ul u-ph-small'
            style={{ listStyleType: 'lower-alpha' }}
        >
            <li className='u-mv-xsmall'>
                Infringir los derechos de propiedad intelectual y de privacidad,
                entre otros, los derechos de autor (copyright), los derechos
                sobre la base de datos, las marcas registradas o el know how de
                terceros;
            </li>
            <li className='u-mv-xsmall'>
                Descargar, enviar, transmitir o almacenar material que:
                <ul
                    className='c-ul u-ph-small'
                    style={{ listStyleType: 'disc' }}
                >
                    <li className='u-mv-xsmall'>
                        Infrinja las obligaciones contractuales o de
                        confidencialidad del Usuario,
                    </li>
                    <li className='u-mv-xsmall'>
                        Perjudique o interfiera en las aplicaciones normales de
                        la Plataforma, como el envío o la transmisión de virus,
                        gusanos o troyanos.
                    </li>
                </ul>
            </li>
            <li className='u-mv-xsmall'>
                Contravenir, o intentar contravenir, las medidas de seguridad de
                la Plataforma;
            </li>
            <li className='u-mv-xsmall'>
                Acceder o intentar acceder a la cuenta o al login de las
                terceras personas o empresas indicadas en el Plataforma;
            </li>
            <li className='u-mv-xsmall'>
                Utilizar la Plataforma Digital de forma no autorizada o para
                alguna actividad delictiva;
            </li>
        </ul>
        <h4>Utilización del sitio y obligaciones</h4>
        <p className='u-pb-small'>El Usuario deberá:</p>
        <ul
            className='c-ul u-ph-small'
            style={{ listStyleType: 'lower-alpha' }}
        >
            <li className='u-mv-xsmall'>
                Utilizar la Plataforma únicamente para los fines legalmente
                permitidos.
            </li>
            <li className='u-mv-xsmall'>
                Proteger los datos personales que conforman la Información de
                los Clientes.
            </li>
            <li className='u-mv-xsmall'>
                Enviar solamente material sobre el que el Usuario tenga los
                correspondientes derechos o licencia para hacerlo;
            </li>
            <li className='u-mv-xsmall'>
                Utilizar su propio criterio, precaución y sentido común al
                gestionar la información conseguida a través de la Plataforma.
            </li>
            <li className='u-mv-xsmall'>
                Mantener la confidencialidad de dichos cuenta de acceso y
                contraseña;
            </li>
            <li className='u-mv-xsmall'>
                Actualizar y comprobar frecuentemente su contraseña; y
            </li>
            <li className='u-mv-xsmall'>
                g. Notificar inmediatamente a Volkswagen Financial Services, si
                tiene conocimiento del uso no autorizado de su cuenta o de
                cualquier vulneración de las medidas de seguridad.
            </li>
        </ul>
        <h4>Responsabilidades</h4>
        <ul
            className='c-ul u-ph-small'
            style={{ listStyleType: 'lower-alpha' }}
        >
            <li className='u-mv-xsmall'>
                Volkswagen Financial Services no asume responsabilidad alguna
                por el funcionamiento o cualesquier tipo de daños y/o perjuicios
                que el Usuario estime puedan derivarse de la interacción de esta
                Plataforma con su dispositivo móvil, equipo de cómputo o
                cualquier otro equipo, ni derivado de presencia de virus, de
                programas malicioso o lesivos en los contenidos, ni por
                cualquier otro elemento que pudiera ser introducido por
                cualquier tercero violando los controles que tenemos en nuestra
                Plataforma, ni por cualquier otra aplicación que pretenda
                suplantar ésta.
            </li>
            <li className='u-mv-xsmall'>
                La Plataforma ha sido revisada y aprobada para que funcione
                correctamente. Sin embargo no se garantiza la disponibilidad y
                continuidad total o parcial de ésta o las secciones que la
                integran. Volkswagen Financial Services no asume responsabilidad
                alguna por cualquier daño o perjuicio derivado de la falta de
                continuidad o disponibilidad de conexión a la Aplicación.
            </li>
            <li className='u-mv-xsmall'>
                Volkswagen Financial Services no asume responsabilidad alguna
                por cualquier daño o perjuicio derivado de:
                <ul
                    className='c-ul u-ph-small'
                    style={{ listStyleType: 'disc' }}
                >
                    <li className='u-mv-xsmall'>
                        El uso inadecuado por parte del Usuario respecto de los
                        elementos que conforman su contenido.
                    </li>
                    <li className='u-mv-xsmall'>
                        El funcionamiento de cualquiera de los enlaces y/o ligas
                        (links) contenidos en esta Aplicación y/o la calidad,
                        licitud, fiabilidad y utilidad de los productos,
                        servicios, información o cualesquiera elementos de los
                        contenidos en otros sitios web y/o redes sociales y/o
                        aplicaciones vinculadas desde o con esta Plataforma.
                    </li>
                </ul>
            </li>
            <li className='u-mv-xsmall'>
                Esta Plataforma es para uso del Usuario previamente registrado.
                Por el acceso y uso de esta Plataforma, en ningún momento ni
                bajo ningún concepto el Usuario podrá considerar que goza de
                cualquier derecho o licencias para el uso y/o explotación de
                cualquiera de los elementos que conforman el contenido de esta
                Plataforma. Cualquier otro uso distinto al estrictamente
                mencionado de la Plataforma por parte del Usuario requiere de
                autorización previa por Volkswagen Financial Services por
                escrito.
            </li>
            <li className='u-mv-xsmall'>
                La información contenida en esta Plataforma, es de carácter
                confidencial y Volkswagen Financial Services ha tomado todas las
                medidas para que el Usuario no haga el mal uso de la
                información.
            </li>
            <li className='u-mv-xsmall'>
                Los productos y/o servicios ofertados, así como los beneficios
                y/o promociones otorgadas que aparezcan publicitados o referidas
                en esta Plataforma, son responsabilidad sólo de la empresa que
                los esté ofertando u otorgando.
            </li>
            <li className='u-mv-xsmall'>
                Volkswagen Financial Services se reserva el derecho a
                interrumpir, restringir, modificar o suspender en cualquier
                momento o de vez en cuando y de forma temporal o permanente el
                funcionamiento de esta Plataforma, ya sea parcialmente o en su
                totalidad, con o sin previo aviso.
            </li>
        </ul>
        <h4>Derechos de Propiedad Industrial e Intelectual</h4>
        <p className='u-pb-small'>
            Todo el contenido de esta Plataforma es propiedad de Volkswagen
            Financial Services (cada uno o en conjunto) Por tanto, los derechos
            sobre dicho contenido se encuentran protegidos por las leyes
            mexicanas aplicables y/o por el derecho internacional.
        </p>
        <p className='u-pb-small'>
            Asimismo se encuentran protegidos los derechos sobre las palabras,
            imágenes, colores, que conforman cada uno del (los) logotipo(s) que
            identifican a cada marca del Grupo Volkswagen (no importando sus
            colores, tipos de representaciones gráficas, forma, tamaños u otras
            características) y cualquier otra palabra y cualquier otro elemento
            que sirva para distinguir a los productos, siluetas, imágenes,
            servicios, marcas, lemas publicitarios, videos o cualesquiera otros
            elementos de las empresas de Audi y de Volkswagen, que se encuentren
            contenidos actualmente o se inserten en el futuro. Ninguno de estos
            elementos puede ser utilizado sin el previo permiso por escrito de
            su(s) titular(es), quedando por tanto prohibida su reproducción,
            modificación, distribución, transmisión, re-publicación, exhibición
            o ejecución, así como cualquier otro uso del contenido de esta
            Aplicación en alguna otra página de Internet, red informática,
            aplicación y de otra forma, incluso en la publicidad y anuncios en
            relación con la conformación de esta Plataforma.
        </p>
        <p className='u-pb-small'>
            Queda prohibido al Usuario intentar obtener información, mensajes,
            archivos de sonido y/o de imagen (incluyendo fotografías,
            grabaciones, videos, dibujos, software, etc.) o cualquier otro
            elemento de esta Plataforma empleando cualquier medio distinto a los
            puestos expresamente a disposición del Usuario para tal fin.
        </p>
        <p className='u-pb-small'>
            De igual forma el Usuario deberá abstenerse de intentar realizar, o
            realizar cualquier tipo de adición, supresión, transformación, o
            cualquier otra forma de alteración al contenido de esta Plataforma,
            así como de realizar ingeniería inversa, descompilar, desensamblar o
            intentar descubrir el código fuente de la misma.
        </p>
        <p className='u-pb-small'>
            Al usar la Plataforma, el Usuario acepta cumplir con todas las
            leyes, regulaciones y otros requisitos legales relacionados con el
            acceso y uso de la Plataforma. Cualquier uso no autorizado del
            contenido de esta Plataforma será violatorio de la Ley de la
            Propiedad Industrial, la Ley Federal del Derecho de Autor, las leyes
            civiles, mercantiles, penales y/o demás leyes nacionales o normas
            internacionales aplicables, y dará derecho a cualquier afectado
            requerir la indemnización correspondiente por parte del infractor e
            implementar cualquier medida para evitar que se le causen o
            continúen causándosele daños y perjuicios.
        </p>
        <p className='u-pb-small'>
            El Usuario acepta informar de manera inmediata a Volkswagen
            Financial Services si sospecha que se ha producido una actividad
            ilegal, fraudulenta o abusiva, o cualquier otra violación de estos
            Términos y Condiciones, incluyendo cualquier acceso no autorizado a
            la Aplicación.
        </p>
        <h4>Protección de Datos Personales</h4>
        <p className='u-pb-small'>
            Para poder ingresar y hacer uso de la Plataforma, el Usuario debe
            haber leído y aceptado el Aviso de Privacidad contenido en la página
            principal. La información completa sobre el uso de los datos
            personales se describe en dicho Aviso de Privacidad que está
            disponible para su consulta a través de esta Plataforma en el
            apartado Aviso de Privacidad.
        </p>
        <h4>Competencia, jurisdicción y derecho aplicable</h4>
        <p className='u-pb-small'>
            El Usuario al hacer uso de esta Plataforma acepta que está sujeta a
            las leyes federales aplicables en los Estados Unidos Mexicanos y que
            cualquier controversia que se derive del uso e interacción con esta
            la Aplicación o de cualquier cuestión relacionada con esta, se
            dirimirá ante los tribunales competentes de la ciudad de Puebla,
            Puebla, renunciando expresamente a cualquier otra jurisdicción que
            pudiera corresponderles por razón de su domicilio presente o futuro
            o por cualquier otra razón.
        </p>
        <h4>Actualización de Términos y Condiciones</h4>
        <p className='u-pb-small'>
            Volkswagen Financial Services se reserva expresamente el derecho de
            actualizar en cualquier momento los presentes Términos y
            Condiciones.
        </p>
        <h4>Divisibilidad</h4>
        <p className='u-pb-small'>
            Si cualquier disposición de estos Términos y Condiciones es o
            llegare a ser contraria a la ley, nula o no aplicable, no afectará
            la validez y aplicabilidad de cualquier otra disposición.
        </p>
        <h4>Contacto</h4>
        <p className='u-pb-small'>
            En caso de que el Usuario tenga cualquier pregunta, comentario o
            solicitud relacionada con esta Plataforma, podrá ponerse en contacto
            directo con VWFS.
        </p>
        <p className='u-pb-small'>
            <b>
                Volkswagen Financial Services, es el nombre comercial de las
                entidades del grupo financiero Volkswagen que integran a
                Volkswagen Leasing, S.A. de C.V., Volkswagen Bank, S.A.
                Institución de Banca Múltiple y Volkswagen Insurance Brokers,
                Agente de Seguros y de Fianzas, S.A. de C.V. con domicilio en
                Autopista México-Puebla Km 116 más 900, San Lorenzo Almecatla,
                C.P. 72700 Cuautlancingo, Puebla, México. Entidades debidamente
                constituidas de acuerdo a las Leyes Mexicanas.
            </b>
        </p>
        <p className='u-pb-small'>Ultima actualización 10/05/2023.</p>
    </div>
);
