import { ApiGatewayClientConfig, KeycloakConfig } from '@dh/keycloak-auth';

/**
 * Initial configuration for the keycloak instance, this substitute the usual
 * keycloak.json that you normal put in /public
 */
export const keycloakConfig: KeycloakConfig = {
    realm: process.env.REACT_APP_REALM || 'vwfs',
    url:
        process.env.REACT_APP_URL ||
        'https://cons.identity.digitalhub.vwfs.io/auth',
    clientId: process.env.REACT_APP_CLIENT_ID || 'alondra',
};

/**
 * Insurance back API credentials
 */
export const apiGatewayClientConfig: ApiGatewayClientConfig = {
    region: 'ca-central-1',
    invokerUrl:
        process.env.REACT_APP_API_URL ||
        'https://243qygu5i2.execute-api.ca-central-1.amazonaws.com/cons/insurance',
    authorization: '',
};
/**
 * Alondra url for callback
 */
export const alondraCallbackUrl =
    process.env.REACT_APP_API_ALONDRA_URL ||
    'https://core.cons.alondra.nar.vwfs.io/app/callback';

export const consumerIdApi = process.env.CONSUMER_ID_API || 'VWIB';
/**
 * Role mapping from keycloak
 */
export const insuranceRoles = 'alondra_rol';

/**
 * Roles definition for the application
 * [Keycloak role name in token] = [Application role name]
 */
export enum Roles {
    IT = 'IT-ADMIN',
    ADMIN = 'Administrador',
    VWFS_EJEC_TARIF = 'VWFS-EJEC-TARIF',
    VWFS_COTIZ = 'VWFS-COTIZ',
    VWFS_ESPE_CANCE = 'VWFS-ESPE-CANCE',
    VWFS_ESPE_CANCE_URG = 'VWFS-ESPE-CANCE-URG',
    VWFS_ESPE_PAG = 'VWFS-ESPE-PAG',
    VWFS_ESPE_END = 'VWFS-ESPE-END',
    VWFS_ESPE_EMISI = 'VWFS-ESPE-EMISI',
    VWFS_EMISI = 'VWFS-EMISI',
    CAC_GEN = 'CAC-GEN',
    VWFS_OPER_GEN = 'VWFS-OPER-GEN',
    VWFS_ESPE_POST = 'VWFS-ESPE-POST',
    VWFS_ESPE_SIN = 'VWFS-ESPE-SIN',
    VWFS_EJEC_SIN = 'VWFS-EJEC-SIN',
    VWFS_COOR_SIN = 'VWFS-COOR-SIN',
    VWFS_COTIZ_G = 'VWFS-COTIZ-G',
    VWFS_EMISI_G = 'VWFS-EMISI-G',
}
