import { Roles } from '../config';

/**
 * Roles allowed Home
 */
export const allowedHomeRoles = [
    Roles.IT,
    Roles.ADMIN,
    Roles.VWFS_EJEC_TARIF,
    Roles.VWFS_COTIZ,
    Roles.VWFS_ESPE_CANCE,
    Roles.VWFS_ESPE_CANCE_URG,
    Roles.VWFS_ESPE_PAG,
    Roles.VWFS_ESPE_END,
    Roles.VWFS_ESPE_EMISI,
    Roles.VWFS_EMISI,
    Roles.CAC_GEN,
    Roles.VWFS_OPER_GEN,
    Roles.VWFS_EJEC_SIN,
    Roles.VWFS_ESPE_SIN,
    Roles.VWFS_ESPE_POST,
    Roles.VWFS_COOR_SIN,
    Roles.VWFS_COTIZ_G,
    Roles.VWFS_EMISI_G,
];
